<template>
  <form @submit.prevent class="w-100 h-100">
    <div class="row mx-0 h-100 justify-content-center">
      <div class="col-12 px-0 white_bg">
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0" v-if="isReset">
            <div class="row mt-4 justify-content-center text-center">
              <div class="col-7 col-md-6 text-center">
                <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
                <TickAnimation v-else />
              </div>
              <div class="col-12 mb-3 text-center medium font23 green-text">
                Password Reset Succesfully
              </div>
            </div>
          </div>
          <div class="col-12 p-0" v-else>
            <div class="row m-0 justify-content-center">
              <div class="col-12 text-center p-0 mb-3 card_heading">
                Reset Password
              </div>
              <div class="col-10 mb-3">
                <TextField type="password" label="New Password:" placeholder="Password" v-model="password" id="password" color="gold" autocomplete="new-password" :errors="v$.password.$errors" :hasErrors="v$.password.$errors.length" />
              </div>
              <div class="col-10 mb-3">
                <TextField type="password" label="Confirm Password:" placeholder="Confirm Password" v-model="confirmPassword" id="confirmPassword" color="gold" autocomplete="confirm-password" :theError="(confirmErrors > 0) ? 'Passwords do not match' : null" :hasErrors="confirmErrors" />
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 justify-content-center" v-if="errorMessage">
          <div class="col-11 pb-3 text-center red-text small">
            <span>{{errorMessage}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 align-self-end">
        <div class="row m-0 background-green py-3 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-auto">
                <Button color="red" width="100" btnText="Login" icon="arrow" @buttonClicked="goLogin" v-if="isReset">
                  <IconArrowForward color="white" size="size16" />
                </Button>
                <Button color="green_light" width="100" btnText="Submit" icon="arrow" @buttonClicked="reset" v-else>
                  <IconArrowForward color="white" size="size16" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Reset',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      password: {
        required: helpers.withMessage('Password must be at least 8 characters, contain at least 1 letter, 1 number and 1 special character', required),
        minLength: helpers.withMessage('Password must be at least 8 characters, contain at least 1 letter, 1 number and 1 special character', minLength(8)),
        containsLetter: helpers.withMessage('Password must be at least 8 characters, contain at least 1 letter, 1 number and 1 special character', this.containsLetters),
        containsNumber: helpers.withMessage('Password must be at least 8 characters, contain at least 1 letter, 1 number and 1 special character', this.containsNumbers),
        containsSpecial: helpers.withMessage('Password must be at least 8 characters, contain at least 1 letter, 1 number and 1 special character', this.containsSpecials)
      }
    }
  },
  data () {
    return {
      password: '',
      confirmPassword: '',
      confirmErrors: 0,
      passwordErrors: 0,
      token: '',
      isReset: false,
      errorMessage: null
    }
  },
  async mounted () {
    this.token = this.$route.query.forgotToken
  },
  computed: {
    ...mapGetters([
      'isApp'
    ])
  },
  methods: {
    ...mapActions([
      'resetPasswordVerify'
    ]),
    async reset () {
      this.v$.$touch()
      if (this.v$.password.$invalid) {
        this.passwordErrors = 1
        return false
      }
      if (this.password !== this.confirmPassword) {
        this.confirmErrors = 1
        return false
      }
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      const credentials = {
        password: this.password,
        confirmationPassword: this.confirmPassword,
        token: this.token
      }
      try {
        const ret = await this.resetPasswordVerify(credentials)
        if (ret) {
          this.isReset = true
        }
      } catch (e) {
        this.errorMessage = 'There was an error updating your password, please try again.'
      }
    },
    goLogin () {
      this.$emit('login')
    },
    containsLetters (value) {
      return /[a-zA-Z]/.test(value)
    },
    containsNumbers (value) {
      return /[0-9]/.test(value)
    },
    containsSpecials (value) {
      return /[#?!@$%^&*-]/.test(value)
    }
  }
}
</script>

<style scoped>
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green-color);
}
.background-green {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  color: #fff;
  font-weight: bold;
}
.cursor:hover {
  text-decoration: underline;
}
</style>
